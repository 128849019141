//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: 'NewsDetails',
    data() {
        return {
            model: {}
        }
    },
    methods: {
        back() {
            let self = this;
            self.$router.push({ name: "SocialResponsibility" })
        },
        loadData() {
            let self = this;
            self.axios
                .get(process.env.VUE_APP_APIEndPoint + 'Multimedia/PublicGetOne/' + self.$route.params?.id)
                .then(res => {
                    self.model = res.data.Data;
                });
        }
    },
    mounted() {
        this.loadData();
    },
    watch: {
        $route() {
            this.loadData();
        }
    },
    updated() {
    }
}
